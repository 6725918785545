import { IonModal } from "@ionic/react";
import { Shift } from "@src/appV2/Shifts/Shift/types";

import { SentHomeRequestPage } from "./sentHomeRequestPage";

export const SentHomeRequestModal: React.VFC<{
  isOpen: boolean;
  closeModal: () => void;
  shift: Shift;
}> = ({ isOpen, closeModal, shift }) => (
  <IonModal
    cssClass="sent-home-request-modal"
    data-testid="sent-home-request-modal"
    isOpen={isOpen}
    onDidDismiss={() => closeModal()}
  >
    <SentHomeRequestPage shift={shift} onClose={() => closeModal()} />
  </IonModal>
);
