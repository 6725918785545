import { isDefined } from "@clipboard-health/util-ts";
import { isSameMinute, parseISO } from "date-fns";

interface ClockInOut {
  start?: string;
  end?: string;
}

interface IsTimesheetChangedParams {
  originalClockInOut: ClockInOut;
  originalLunchInOut: ClockInOut;
  editedClockInOut: ClockInOut;
  editedLunchInOut: ClockInOut;
}

export function isSameMinuteOrAreBothUndefined(
  editedTime: string | undefined,
  actualTime: string | undefined
): boolean {
  if (!isDefined(editedTime) && !isDefined(actualTime)) {
    return true;
  }
  if (!isDefined(editedTime) || !isDefined(actualTime)) {
    return false;
  }
  return isSameMinute(parseISO(editedTime), parseISO(actualTime));
}

export function isTimesheetChanged(params: IsTimesheetChangedParams): boolean {
  const { originalClockInOut, originalLunchInOut, editedClockInOut, editedLunchInOut } = params;
  return (
    !isSameMinuteOrAreBothUndefined(editedClockInOut.start, originalClockInOut.start) ||
    !isSameMinuteOrAreBothUndefined(editedClockInOut.end, originalClockInOut.end) ||
    !isSameMinuteOrAreBothUndefined(editedLunchInOut.start, originalLunchInOut.start) ||
    !isSameMinuteOrAreBothUndefined(editedLunchInOut.end, originalLunchInOut.end)
  );
}

function buildHelperText(skippedText: string) {
  return function (editedTime: string | undefined, originalTime: string | undefined): string {
    if (isSameMinuteOrAreBothUndefined(editedTime, originalTime)) {
      return "";
    }

    if (originalTime === undefined) {
      return skippedText;
    }

    return originalTime;
  };
}

export const getClockInEditHelperText = buildHelperText("skipped clock-in");

export const getLunchOutEditHelperText = buildHelperText("skipped break");

export const getLunchInEditHelperText = buildHelperText("");

export const getClockOutEditHelperText = buildHelperText("skipped clock-out");
