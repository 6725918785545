import { IonCardSubtitle } from "@ionic/react";
import { Agent } from "@src/lib/interface";

import { SupportText } from "./support";

const MissingDocsDetails: React.VFC<{
  agent?: Agent;
  hasMissingDocs?: boolean;
}> = ({ agent, hasMissingDocs }) => {
  if (agent || !hasMissingDocs) {
    return null;
  }

  return (
    <>
      <IonCardSubtitle className="title no-text-transform ion-margin-top">
        You are Missing Documents
      </IonCardSubtitle>
      <p>
        There are a number of missing documents that we require before we can assign you to this
        shift.
      </p>
      <SupportText />
    </>
  );
};

export { MissingDocsDetails };
