import "./style.scss";
import { IonAlert } from "@ionic/react";
import { useEffect, useState } from "react";

import { useRecognizedHoliday } from "./hooks";
import { LocalStorage } from "../store/session";

export const HolidayAlert = () => {
  const [isOpen, setIsOpen] = useState(false);
  const recognizedHoliday = useRecognizedHoliday();

  useEffect(() => {
    if (!recognizedHoliday) {
      return;
    }
    const holidays: string[] = JSON.parse(
      localStorage.getItem(LocalStorage.RECOGNIZED_HOLIDAYS) ?? "[]"
    );

    /**
     * Added to ensure that tests are deterministic and we do not see these pop-ups appearing on given days based on shifts being on national holidays
     * https://linear.app/clipboardhealth/issue/ACT-456/failing-e2e-tests-due-to-holiday-information-popup-preventing-clicking
     */
    const areHolidayPopupsVisible = localStorage.getItem("areHolidayPopupsVisible") !== "false";

    if (areHolidayPopupsVisible && !holidays.includes(recognizedHoliday)) {
      setIsOpen(true);
      localStorage.setItem(
        LocalStorage.RECOGNIZED_HOLIDAYS,
        JSON.stringify(Array.from(new Set([...holidays, recognizedHoliday])))
      );
    }
  }, [recognizedHoliday]);

  return (
    <IonAlert
      cssClass="holiday-alert"
      mode="ios"
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      header={`${recognizedHoliday} is a recognized holiday!`}
      message="Many facilities offer a higher hourly pay rate for shifts on select holidays throughout the year! If offered, Holiday Pay will already be included in the quoted pay rate for available shifts."
      buttons={["OK"]}
    />
  );
};
