import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { Shift } from "@src/lib/interface";
import moment from "moment-timezone";

import { ShiftTimelineItem } from "./shiftTimelineItem";
import { getIsEarlyOrIsLateMessage } from "../../../../utils/shiftTimeMessage";

interface ShiftTimelineProps {
  shift: Shift;
  isSolveUnpaidBreaksEnabled: boolean;
}

export function ShiftTimeline(props: ShiftTimelineProps) {
  const { shift, isSolveUnpaidBreaksEnabled } = props;

  const { clockInOut = {}, lunchInOut = {} } = shift;
  const facilityTimeZone = shift?.facility?.tmz ? shift.facility.tmz : "";
  const startTimeHelperTxt = getIsEarlyOrIsLateMessage(
    clockInOut?.start ? moment(clockInOut?.start).diff(moment(shift?.start), "seconds") : 0
  );

  const endTimeHelperTxt = getIsEarlyOrIsLateMessage(
    moment(clockInOut?.end).diff(moment(shift?.end), "seconds")
  );
  const isClockInPresent = isDefined(clockInOut?.start);
  const isClockOutPresent = isDefined(clockInOut.end);
  const isLunchStartPresent = isDefined(lunchInOut?.start) || isDefined(lunchInOut?.isSkipped);
  const isLunchEndPresent = isDefined(lunchInOut?.end) || isDefined(lunchInOut?.isSkipped);

  return (
    <div className="hcp-timeline-list">
      {/* Condition: disabled if
            - Is not clocked out
            - clockin start is not there */}
      <ShiftTimelineItem
        title="CLOCK IN"
        timeRange={clockInOut?.start}
        disabled={!isClockInPresent && !isClockOutPresent}
        helperText={startTimeHelperTxt}
        facilityTimeZone={facilityTimeZone}
      />
      {!isSolveUnpaidBreaksEnabled && (
        <>
          {/* Condition: disabled if
            - Is not clocked out
              - clockin start is not there
              - If lunch start is not there and lunch is not skipped */}
          <ShiftTimelineItem
            title="START BREAK"
            timeRange={lunchInOut?.start}
            disabled={!isClockOutPresent && (!isClockInPresent || !isLunchStartPresent)}
            facilityTimeZone={facilityTimeZone}
          />
          {/*
          Condition: disabled if
            - Is not clocked out
              - clockin start is not there
              - If lunch start and end are not there and lunch is not skipped */}
          <ShiftTimelineItem
            title="END BREAK"
            timeRange={lunchInOut?.end}
            disabled={!isClockOutPresent && (!isLunchStartPresent || !isLunchEndPresent)}
            facilityTimeZone={facilityTimeZone}
          />
        </>
      )}
      <ShiftTimelineItem
        title="CLOCK OUT"
        timeRange={clockInOut?.end}
        disabled={!isClockOutPresent}
        helperText={endTimeHelperTxt}
        facilityTimeZone={facilityTimeZone}
      />
    </div>
  );
}
