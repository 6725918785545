import { Link } from "react-router-dom";

import { supportText } from "../../../constants/ShiftInstructionMessages";
import { RouterPath } from "../../routing/constant";

const SupportText = () => (
  <div>
    <p>
      {supportText}
      <Link to={RouterPath.NEED_HELP}>Help Center</Link>.
    </p>
  </div>
);

export { SupportText };
